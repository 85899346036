import styled from "styled-components";

import { device, theme } from "../../../Theme";

const Wrapper = styled.div`
  p{
    font-size:16px;
  }

  @media only screen and (max-width: 1200px){
    .jack{
      display:none;
    }
  }
`;

export default Wrapper;
