const Blockquote = ({ fill }) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="200"
      height="200"
    >
      <path
        fill={fill}
        d="m114.4 89.1c0-22.2 2.5-63.4 85.6-63.4v18.2c-39.9 0-44.9 25.3-42.7 45.2h42.7v85.6h-85.6c0 0 0-85.6 0-85.6zm77.8 77.8v-70h-41.9c-8.5-51.9 27.5-59.2 41.9-60.5v-2.7c-19.1 2.1-70-0.8-70 55.4v77.8zm-192.2-77.8c0-22.2 2.6-63.4 85.6-63.4v18.2c-39.9 0-44.9 25.3-42.7 45.2h42.7v85.6h-85.6zm77.8 77.8v-70h-41.9c-8.5-51.9 27.6-59.2 41.9-60.5v-2.7c-19.1 2.1-70-0.8-70 55.4v77.8z"
      />
    </svg>
  );
};

export default Blockquote;
