const Arrow = ({ fill }) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="200"
      height="200"
    >
      <path
        fill={fill}
        d="m199.2 95.6c0.9 1 1.2 2.9 0 4.4l-51.6 54.8c-1.4 1.4-3.4 1.3-4.6 0.2-1.2-1.2-1.3-3.4-0.2-4.6l46.5-49.4h-186.1c-1.8 0-3.2-1.5-3.2-3.2 0-1.8 1.4-3.3 3.2-3.3h186.1l-46.5-49.4c-1.1-1.2-1-3.4 0.2-4.5 1.2-1.2 3.3-1.2 4.6 0.1l51.6 54.8z"
      />
    </svg>
  );
};

export default Arrow;
