import React from 'react';

//Import Card component
import Card from '../Card';

import Container from '../../styled_components/Container';
import Heading from '../../styled_components/Heading';

function ComoFunciona({data}) {
  return (
    <Container backgroundColor="#1abc9c" width="100%" padding="5rem 0">
        <Container
          maxWidth="1440px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
          margin="0 auto"
        >
          <Heading textAlign="center" fontSize='2.5rem' margin="3rem" fontWeight='bold' textColor="#fff">Como funciona?</Heading>
          <Container
            display="flex"
            flexDirection="column"
            laptopFlexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            laptopAlignItems="flex-start"
            width="80%"
            margin="2rem auto"
          >
            {data &&
              data.comoFunciona.map((item, idx) => {
                return (
                  <Card
                    colorHeader='White'
                    colorText='black'
                    key={idx}
                    img={item.img}
                    title={item.title}
                    subtitle={item.subtitle}
                    idx={idx}
                  />
                );
              })}
          </Container>
        </Container>
      </Container>
  )
}

export default ComoFunciona