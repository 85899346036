import styled from "styled-components";

import { device, theme } from "../../../Theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 3rem 0;
  flex-direction: column;
  width: 50%;

  .counter-number {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 1rem;
  }
  
  .counter-number span:nth-of-type(1) {
    font-size: 5rem;
    font-weight: 700;
    color: #1abc9c;
    line-height: 3.5rem;
  }

  .counter-number span:nth-of-type(2) {
    font-size: 2rem;
    font-weight: 700;
    color: #1abc9c;
  }

  .counter-text {
    text-align: center;
    width: 50%;
  }

  .counter-text p {
    margin: 0;
    font-size:16px;
  }
`;

export default Wrapper;
