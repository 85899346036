import React from 'react'
import Wrapper from "./Wrapper";

import Container from '../../styled_components/Container';
import Heading from '../../styled_components/Heading';

export default function QuemSomos({ data }) {
    return (
      <Wrapper>
        <Container flex flexDirection='column' laptopFlexDirection='row' justifyContent='center' width='100%' laptopHeight='400px' backgroundColor='#1bbc9c47'>
          <Container padding='3rem' flex flexDirection='column' justifyContent='flex-start'  laptopWidth='30%'>
            <Heading fontWeight='600' fontSize='34px'>{data.quemSomos[0].title}</Heading>
            <p>{data.quemSomos[0].text}</p>
            <p>{data.quemSomos[0].text2}</p>
          </Container>
          <Container margin='0 2rem' className='jack'>
            <Container height='300px' laptopHeight='100%'>
              <img style={{height:'100%'}} src='/imgs/Cable_Diatech.png'/>
            </Container>
          </Container>
          <Container textAlign='left'  padding='3rem' flex flexDirection='column' justifyContent='flex-start' laptopWidth='30%'>
            <Heading textAlign='left' fontWeight='600' fontSize='34px'>{data.quemSomos[1].title}</Heading>
            <p>{data.quemSomos[1].text}</p>
            <p>{data.quemSomos[1].text2}</p>
            <p>{data.quemSomos[1].text3}</p>
          </Container>
        </Container>   
      </Wrapper>
  )
}
