const WavyBg = ({ svgId, primaryFill, secondaryFill }) => {
  return (
    <svg
      viewBox="0 0 1440 140"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={`wavyBg${svgId}`}
    >
      <defs>
        <linearGradient id={`sw-gradient-${svgId}`} x1="0" x2="1" y1="0" y2="0">
          <stop stopColor={primaryFill} offset="0%"></stop>
          <stop stopColor={secondaryFill} offset="50%"></stop>
        </linearGradient>
      </defs>
      <path
        fill={`url(#sw-gradient-${svgId})`}
        d="M0,0L24,9.3C48,19,96,37,144,49C192,61,240,65,288,65.3C336,65,384,61,432,51.3C480,42,528,28,576,21C624,14,672,14,720,30.3C768,47,816,79,864,93.3C912,107,960,103,1008,102.7C1056,103,1104,107,1152,105C1200,103,1248,93,1296,81.7C1344,70,1392,56,1440,58.3C1488,61,1536,79,1584,79.3C1632,79,1680,61,1728,53.7C1776,47,1824,51,1872,58.3C1920,65,1968,75,2016,72.3C2064,70,2112,56,2160,49C2208,42,2256,42,2304,35C2352,28,2400,14,2448,25.7C2496,37,2544,75,2592,91C2640,107,2688,103,2736,88.7C2784,75,2832,51,2880,37.3C2928,23,2976,19,3024,30.3C3072,42,3120,70,3168,72.3C3216,75,3264,51,3312,53.7C3360,56,3408,84,3432,98L3456,112L3456,140L3432,140C3408,140,3360,140,3312,140C3264,140,3216,140,3168,140C3120,140,3072,140,3024,140C2976,140,2928,140,2880,140C2832,140,2784,140,2736,140C2688,140,2640,140,2592,140C2544,140,2496,140,2448,140C2400,140,2352,140,2304,140C2256,140,2208,140,2160,140C2112,140,2064,140,2016,140C1968,140,1920,140,1872,140C1824,140,1776,140,1728,140C1680,140,1632,140,1584,140C1536,140,1488,140,1440,140C1392,140,1344,140,1296,140C1248,140,1200,140,1152,140C1104,140,1056,140,1008,140C960,140,912,140,864,140C816,140,768,140,720,140C672,140,624,140,576,140C528,140,480,140,432,140C384,140,336,140,288,140C240,140,192,140,144,140C96,140,48,140,24,140L0,140Z"
      ></path>
    </svg>
  );
};

export default WavyBg;
