import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RobotCozinha from "./Paginas/RobotCozinha/App.jsx";
import ReparacoesTV from "./Paginas/ReparacoesTV/App.jsx";
import Thankyou from "./Paginas/Thankyou/App.jsx";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/robot-cozinha" element={<RobotCozinha />} />
          <Route exaxt path="/reparacoes-tv" element={<ReparacoesTV />} />
          <Route exaxt path="/obrigado" element={<Thankyou />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
