import PropTypes from "prop-types";
import Wrapper from "./Wrapper";

const Heading = (props) => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
};

Heading.defaultProps = {
  level: "2",
  text: "Text here",
  textColor: "primary",
  fontFamily: "primary",
  textAlign: "left",
  width: "100%",
  uppercase: false,
  margin: "0",
  padding: "0",
};

Heading.propTypes = {
  level: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  fontFamily: PropTypes.oneOf(["primary", "secondary"]),
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  width: PropTypes.string,
  uppercase: PropTypes.bool,
  margin: PropTypes.string,
  padding: PropTypes.string,
};

export default Heading;
