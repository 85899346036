import styled from "styled-components";

import { device, theme } from "../../../Theme";

const Wrapper = styled.div`
  background-color: #223535;
  padding: 2rem;
  border-radius: 10px;
  color: #fff;

  .formRow,
  .formCol {
    display: flex;
    margin: 2rem 0;
  }

  .formRow span,
  .formCol span {
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  .formRow {
    flex-direction: row;
  }

  .formCol {
    flex-direction: column;
  }

  input:focus {
    outline: none;
  }

  input:not([type="checkbox"]) {
    height: 30px;
  }

  button {
    width: 100%;
    height: 60px;
    font-weight: 700;
    font-size: 1.3rem;
    background-color: #00ffca;
    border: none;
    position: relative;
    border-radius: 10px;
    z-index: 2;
    bottom: 0;
    left: 0;
    transition: all 300ms ease-in-out;
  }

  button:before {
    content: "";
    position: absolute;
    background-color: #00ffca;
    opacity: 0.4;
    width: 100%;
    border-radius: 10px;
    height: 60px;
    bottom: -5px;
    left: 1%;
    z-index: -1;
    transition: all 300ms ease-in-out;
  }

  button:hover {
    bottom: -5px;
    left: 1%;
    transition: all 300ms ease-in-out;
  }

  button:hover:before {
    left: 0;
    bottom: 0;
    transition: all 300ms ease-in-out;
  }
`;

export default Wrapper;
