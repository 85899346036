const Magnifier = ({ fill }) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="200"
      height="200"
    >
      <g fill={fill}>
        <path
          fillRule="evenodd"
          d="m115.6 113c-9.3 7.2-20.4 10.8-31.4 10.8-15.2 0-30.3-6.8-40.3-19.7-17.4-22.1-13.4-54.3 8.8-71.6 10.7-8.4 24.1-12.2 37.7-10.5 13.5 1.7 25.6 8.5 34 19.3 17.3 22.2 13.4 54.3-8.8 71.7zm2.7-67c-7.1-9.1-17.4-14.9-28.9-16.4-11.5-1.4-22.9 1.8-32 8.9-18.8 14.7-22.2 42.1-7.5 60.9 14.8 18.9 42.1 22.2 60.9 7.5 18.9-14.7 22.2-42.1 7.5-60.9z"
        />
        <path d="m113.5 60c0.7 2.3-0.5 4.6-2.7 5.2-2.3 0.7-4.5-0.6-5.2-2.8-1.7-5.6-5.4-9.9-10.2-12.5-4.5-2.4-10.3-3.2-15.8-1.6-2.2 0.7-4.5-0.6-5.1-2.8-0.7-2.2 0.5-4.5 2.7-5.1 7.8-2.3 15.8-1.3 22.2 2.2 6.7 3.6 11.9 9.7 14.1 17.4z" />
        <path
          fillRule="evenodd"
          d="m182.4 197.8c-1.8 1.4-4 2.2-6.2 2.2-3 0-6.2-1.4-8.9-4-16.6-15.2-24.5-25.5-36.8-45.3l-1.6 1.2c-0.6 0.5-1.5 0.8-2.3 0.8q-0.3 0-0.5 0c-1-0.1-1.9-0.7-2.6-1.5l-10.4-13.3c-8.9 4-18.6 6-28.5 6q-4.4 0-8.8-0.5c-19-2.4-35.9-11.9-47.6-27-11.8-15-17-33.7-14.7-52.7 2.4-18.9 11.9-35.8 27-47.6 31-24.2 76-18.7 100.3 12.3 11.7 15.1 16.9 33.8 14.6 52.7-1.6 13-6.5 24.9-14.3 35l10.4 13.3c1.3 1.7 1 4.1-0.7 5.4l-1.4 1.1c18.2 18.5 30.3 36.6 35.2 46.8 2.9 6 2.1 11.8-2.2 15.1zm-59.4-74.5l1.5-1.2c13-10.5 21.2-25.3 23.3-41.9 2.1-16.9-2.6-33.6-13.1-47-12.5-16.1-31.3-24.5-50.3-24.5-13.7 0-27.5 4.4-39.2 13.5-13.4 10.5-21.9 25.6-24 42.5-2.1 16.9 2.5 33.6 13 47 10.5 13.4 25.6 22 42.5 24 16.6 2.1 33-2.4 46.3-12.4zm4.2 20.2l15.9-12.4-6.7-8.6-7.2 5.6q-0.3 0.3-0.7 0.7-0.5 0.3-0.9 0.6l-7.1 5.5zm50.5 42.5c-3.8-7.8-15.2-25.9-34.4-45.2l-6.7 5.2c12.2 19.5 19.8 29.6 35.9 44.4 0.7 0.7 3.3 2.9 5.2 1.4 1.8-1.4 0.6-4.5 0-5.8z"
        />
      </g>
    </svg>
  );
};

export default Magnifier;
