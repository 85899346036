import React, { useState } from "react";

import PropTypes from "prop-types";
import Wrapper from "./Wrapper";

import Arrow from "../../../SVG/arrow";
import Blockquote from "../../../SVG/blockquote";

const Testemunhos = (props) => {
  const [active, setActive] = useState(0);

  const handlePrev = () => {
    let newActive;
    if (active > 0) {
      newActive = active - 1;
    } else {
      newActive = props.testimonials.length - 1;
    }
    setActive(newActive);
  };

  const handleNext = () => {
    let newActive;
    if (active < props.testimonials.length - 1) {
      newActive = active + 1;
    } else {
      newActive = 0;
    }
    setActive(newActive);
  };

  return (
    <Wrapper {...props}>
      <div className="testimonialArrow prev" onClick={handlePrev}>
        <Arrow fill="#fff" />
      </div>
      <div className="testimonialWrapper">
        <div className="testimonialContentWrapper">
          <span className="testimonialName">
            {props.testimonials[active].name}
          </span>
          <p>
            <span className="testimonialBloquote begin">
              <Blockquote fill="#1abc9c" />
            </span>
            {props.testimonials[active].text}
            <span className="testimonialBloquote end">
              <Blockquote fill="#1abc9c" />
            </span>
          </p>
        </div>
        <div className="testimonial-dots">
          {props.testimonials.map((dot, index) => {
            return (
              <span
                key={index}
                className={index === active ? "active" : ""}
              ></span>
            );
          })}
        </div>
      </div>
      <div className="testimonialArrow next" onClick={handleNext}>
        <Arrow fill="#fff" />
      </div>
    </Wrapper>
  );
};

export default Testemunhos;
