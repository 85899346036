import MoneyBag from "../../SVG/moneyBag";
import Hand from "../../SVG/hand";
import Magnifier from "../../SVG/magnifier";
import Delivery from "../../SVG/delivery";

const data = {
  Header: {
    title: 'O seu Robot de Cozinha não funciona corretamente?',
    img: "/imgs/cooking_robot2.png",
    right: '60%',
    size: '100%',
    top:'7rem'
  },
  ambiente: 
  {
    title: "Ajude o planeta: evite comprar um novo equipamento",
    title1: 'Invista na durabilidade dos seus dispositivos',
    title2: 'e ajude-nos a contribuir para diminuir a pegada ecológica.',
    privacy:'Política de Privacidade'
  },
  comoFunciona: [
    {
      title: "Orçamento",
      subtitle: "O orçamento inicial é comunicado ao cliente",
      img: <MoneyBag fill="#fff" />,
    },
    {
      title: "Recepção",
      subtitle: "Recolha pela Diatech ou entrega pelo cliente em loja",
      img: <Hand fill="#fff" />,
    },
    {
      title: "Diagnóstico",
      subtitle: "Diagnóstico e Reparação",
      img: <Magnifier fill="#fff" />,
    },
    {
      title: "Entrega",
      subtitle: "Recolha e entrega em qualquer local do país",
      img: <Delivery fill="#fff" />,
    },
  ],
  ImgComoFunciona:{img:'/imgs/bimby_semfundo.png'},
  porqueDiatech: [
    {
      number: 95,
      text: "valor indicativo de reparação",
      postfix: "€",
    },
    {
      number: 6,
      text: "meses de Garantia",
    },
    {
      number: 72,
      text: "prazo médio de reparação",
      postfix: "h",
    },
    {
      number: 480,
      postfix: "+",
      text: "equipamentos reparados mensalmente",
    },
  ],
  quemSomos: [
    {
      title: 'QUEM SOMOS?',
      text: 'A Diatech – Serviços Eletrónicos Integrados, surge com a missão de prestar serviços especializados de Instalação, Reparação e Manutenção de Equipamentos de forte componente eletrónica e tecnológica.',
      text2: 'Acreditamos que a correta manutenção e reparação dos equipamentos contribui para um maior retorno do investimento e um planeta mais limpo!',
      img:'/imgs/workingMan.jpeg'
    },
    {
      title: 'A EQUIPA TÉCNICA',
      text: 'A equipa de gestão e direção técnica é constituída pelos sócios fundadores.',
      text2: 'Rodrigo Viegas, Luís Lousa e Filipe Sequeira, todos com mais de 20 anos no setor da eletrónica e tecnologia.',
      text3:' A equipa acredita na arte de reparar, recuperar, restaurar e manter os equipamentos, sejam eles recentes ou antigos (vintage).Quando a equipa técnica efetua uma reparação irá sempre efetuar uma manutenção e revisão ao equipamento na sua plenitude, de modo a garantir que a reparação efetuada não é afetada por outros danos.',
      img:'/imgs/workingMan.jpeg'
    }
  ],
  testemunhos: [
    {
      text: "Profissional de primeira, com preços muito abaixo do esperado, minha Bimby ficou como nova, e o melhor foi a atenção que deixou todos os problemas que ela tinha, sempre me manteve informada durante a manutenção. Eu super recomendo!",
      name: "Helena Cabral",
    },
    {
      text: "Já mandei reparar a minha Bimby. Os funcionários são muito atenciosos e o serviço fica 1/3 mais barato do que na marca RECOMENDO VIVAMENTE.",
      name: "Rosa Correa",
    },
    {
      text: "Muito satisfeito com todo o processo de reparação da minha Bimby. Cumpriram exatamente o que combinamos pelo Facebook. Recomendo sem qualquer dúvida.",
      name: "Pedro Galveira",
    },
    {
      text: "Tinha a minha Bimby avariada e arrumada na arrecadação. Reparam por menos de 100€. Até agora só posso dizer bem.",
      name: "Tiago Miguel",
    },
    {
      text: "Só tenho um comentário sobre o serviço, atendimento. Recomendo, a minha Bimby está de volta. Obrigada pelo bom serviço.",
      name: "Vera Oliveira",
    },
    {
      text: "Serviço impecável, a Bimby veio como nova. Técnicos muito prestáveis, recomendo.",
      name: "Andreia Billy Romeu",
    },
  ],
  siteForm: 'Robot Cozinha',
};

export default data;
