import styled from "styled-components";

import { device, theme } from "../../../Theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .testimonialWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 300px;
    background-color: #fff;
    width: 500px;
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
  }

  .testimonialWrapper::before {
    content: "";
    width: 90%;
    height: 20px;
    background-color: #fff;
    position: absolute;
    bottom: -20px;
    opacity: 0.6;
    border-radius: 0 0 10px 10px;
  }

  .testimonialWrapper::after {
    content: "";
    width: 80%;
    height: 20px;
    background-color: #fff;
    position: absolute;
    bottom: -40px;
    opacity: 0.4;
    border-radius: 0 0 10px 10px;
  }

  .testimonialContentWrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .testimonialWrapper .testimonialName {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1rem 0;
  }

  .testimonialWrapper p {
    font-size: 1rem;
    position: relative;
    color: #757575;
    line-height: 1.5;
  }

  .testimonial-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
  }

  .testimonial-dots span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid #1abc9c;
    margin: 2px;
  }

  .testimonial-dots span.active {
    width: 10px;
    height: 10px;
  }

  .testimonialArrow {
    width: 80px;
    height: 60px;
    display: flex;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    transition: all 300ms ease-in-out;
  }

  .testimonialArrow:hover {
    border: 4px solid #fff;
    padding: 0;
    transition: all 300ms ease-in-out;
  }

  .testimonialArrow.prev {
    transform: scaleX(-1);
  }

  .testimonialArrow svg {
    width: 80%;
    height: 80%;
  }

  .testimonialBloquote {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
  }

  .testimonialBloquote.begin {
    top: -30px;
    left: -30px;
  }

  .testimonialBloquote.end {
    bottom: -20px;
    right: -20px;
    transform: rotate(180deg);
  }

  .testimonialBloquote svg {
    width: 100%;
    height: 100%;
  }
`;

export default Wrapper;
