
// Sections 
import Header from "../../Components/component_sections/Header";
import ComoFunciona from "../../Components/component_sections/ComoFunciona";
import PorqueDiatech from "../../Components/component_sections/Porque_Diatech";
import OQueDizem from "../../Components/component_sections/OQueDizem";
import Ambiente from "../../Components/component_sections/Ambiente";
import QuemSomos from "../../Components/component_sections/QuemSomos";

import data from "../../data/RobotCozinha";


function App() {
  return (
    <div className="app">
      <Header data={data} />
      {/* 
      ###
      SECTION - Como Funciona
      ###
      */}
      <ComoFunciona data={ data }/>
      {/* 
      ###
      SECTION - Porquê a Diatech
      ###
      */}
      <PorqueDiatech data={data} />
      {/* 
      ###
      SECTION - QUEM SOMOS?
      ###
      */}
      <QuemSomos data={data}/>
      {/* 
      ###
      SECTION - O que dizem os nossos clientes
      ###
      */}
      <OQueDizem data={data}/>
      {/* 
      ###
      SECTION - Contactos + Ambiente
      ###
      */}
      <Ambiente/>
    </div>
  );
}

export default App;
