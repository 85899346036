const theme = {
  colors: {
    primary: "black",
    secondary: "",
    tertiary: "",
  },
  fonts: {
    primary: "'Roboto', sans-serif;",
  },
};

const responsiveSizes = {
  xs: "0px",
  sm: "600px",
  md: "900px",
  lg: "1200px",
  xl: "1800px",
};

const device = {
  xs: `(min-width: ${responsiveSizes.xs})`,
  sm: `(min-width: ${responsiveSizes.sm})`,
  md: `(min-width: ${responsiveSizes.md})`,
  lg: `(min-width: ${responsiveSizes.lg})`,
  sl: `(min-width: ${responsiveSizes.sl})`,
};

export { theme, device };
