import MoneyBag from "../../SVG/moneyBag";
import Hand from "../../SVG/hand";
import Magnifier from "../../SVG/magnifier";
import Delivery from "../../SVG/delivery";

const data = {
  Header: {
    title: 'Estava a meio de uma série quando a sua Televisão deixou de funcionar?',
    img: '/imgs/imagemHero.png',
    right: '55%',
    size: '100%',
    top:'0rem'
  },
  ambiente:
  {
    title: "Ajude o planeta: evite comprar um novo equipamento",
    title1: 'Invista na durabilidade dos seus dispositivos',
    title2: 'e ajude-nos a contribuir para diminuir a pegada ecológica.',
    privacy: 'Política de Privacidade'
  },
  comoFunciona: [
    {
      title: "Orçamento",
      subtitle: "O orçamento inicial é comunicado ao cliente",
      img: <MoneyBag fill="#fff" />,
    },
    {
      title: "Recepção",
      subtitle: "Recolha pela Diatech ou entrega pelo cliente em loja",
      img: <Hand fill="#fff" />,
    },
    {
      title: "Diagnóstico",
      subtitle: "Diagnóstico, Validação de Orçamento Final e Reparação",
      img: <Magnifier fill="#fff" />,
    },
    {
      title: "Entrega",
      subtitle: "Recolha em Loja ou entrega no cliente",
      img: <Delivery fill="#fff" />,
    },
  ],
  porqueDiatech: [
    {
      number: 75,
      text: "desde",
      postfix: "€",
    },
    {
      number: 6,
      text: "meses de Garantia",
    },
    {
      number: 72,
      text: "prazo médio de reparação",
      postfix: "h",
    },
    {
      number: 480,
      postfix: "+",
      text: "equipamentos reparados mensalmente",
    },
  ],
  quemSomos: [
    {
      title: 'QUEM SOMOS?',
      text: 'A Diatech – Serviços Eletrónicos Integrados, surge com a missão de prestar serviços especializados de Instalação, Reparação e Manutenção de Equipamentos de forte componente eletrónica e tecnológica.',
      text2: 'Acreditamos que a correta manutenção e reparação dos equipamentos contribui para um maior retorno do investimento e um planeta mais limpo!',
      img:'/imgs/workingMan.jpeg'
    },
    {
      title: 'A EQUIPA TÉCNICA',
      text: 'A equipa de gestão e direção técnica é constituída pelos sócios fundadores.',
      text2: 'Rodrigo Viegas, Luís Lousa e Filipe Sequeira, todos com mais de 20 anos no setor da eletrónica e tecnologia.',
      text3:' A equipa acredita na arte de reparar, recuperar, restaurar e manter os equipamentos, sejam eles recentes ou antigos (vintage).Quando a equipa técnica efetua uma reparação irá sempre efetuar uma manutenção e revisão ao equipamento na sua plenitude, de modo a garantir que a reparação efetuada não é afetada por outros danos.',
      img:'/imgs/workingMan.jpeg'
    }
  ],
  ImgComoFunciona:{img:'/imgs/porqueDiatech.png'},
  testemunhos: [
    {
      text: "Óptimo atendimento! Rapidez, segurança e muito boa relação qualidade de serviço/preço. Recomendo!",
      name: "Estrela Margarida Silva",
    },
    {
      text: "Grandes profissionais. Um atendimento muito personalizado e cuidado. Parabéns à Diatech.",
      name: "Olga Sousa",
    },
    {
      text: "Fantástico Serviço. Muito Simpáticos, prestáveis e de total confiança.",
      name: "Claudia Sofia Maia",
    },
    {
      text: "Rápido e eficiente, durante o período de reparação, houve o cuidade de ir dando ponto de situação. E o valor da reparação muito abaixo do da marca.",
      name: "Maria Fonseca",
    },
    {
      text: "Serviço de qualidade e excelência no profissionalismo e simpatia. Obrigado por todo o apoio que me têm daod pelas reparaç~es dos meus vários equipamentos. Bem hajam.",
      name: "Pê Pê",
    },
    {
      text: "Excelente serviço...recomendo vivamente.",
      name: "Angelo Santos",
    },
  ],
  siteForm: 'TV',
  policy:'Caso o ecrã da sua televisão esteja partido NÃO o poderemos ajudar na reparação da sua TV.'
};

export default data;
