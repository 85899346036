import PropTypes from "prop-types";
import Wrapper from "./Wrapper";


const Card = (props) => {
  return (
    <Wrapper {...props}>
      <div className="imgWrapper">
        <span>.{props.idx + 1}</span>
        {props.img}
      </div>
      <h3 style={{ color:`${props.colorHeader}`}}>{props.title}</h3>
      <h5 style={{ color:`${props.colorText}`}}>{props.subtitle}</h5>
    </Wrapper>
  );
};

export default Card;
