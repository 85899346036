
// Sections
import Header from "../../Components/component_sections/Layout/Header";

function App() {
  return (
    <div className="app">
        <Header />
    </div>
  );
}

export default App;
