import styled from "styled-components";

import { device, theme } from "../../../Theme";

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;

  .wavyBg0 {
    position: absolute;
    bottom: -5%;
    width: 120%;
    left: -10%;
    transform: rotate(-3deg);
  }
  .wavyBg1 {
    position: absolute;
    bottom: -3%;
    z-index: -1;
    transform: scale(1.2);
    left: -10%;
  }
  .wavyBg2 {
    position: absolute;
    bottom: 0;
  }

  .counter-main-image {
    width: 100%;
  }

  @media only screen and (max-width: 1200px){
    .backgroundCircle{
      display:none;
    }
  }
`;

export default Wrapper;
