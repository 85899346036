import styled from "styled-components";

import { device, theme } from "../../../Theme";

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;

  @media ${device.lg} {
    width: 20%;
  }

  .imgWrapper {
    background-color: #1abc9c;
    border-radius: 50%;
    border: 7px solid #fff;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .imgWrapper span {
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    width: 36px;
    height: 36px;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .imgWrapper svg {
    width: 50%;
    height: 50%;
  }

  h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  h5 {
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
  }
`;

export default Wrapper;
