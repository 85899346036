const MoneyBag = ({ fill }) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="200"
      height="200"
    >
      <g fill={fill}>
        <path d="m110 95h10c2.8 0 5 2.2 5 5 0 2.8-2.2 5-5 5h-10c-12.1 0-22.2 8.6-24.5 20h34.5c2.8 0 5 2.2 5 5 0 2.8-2.2 5-5 5h-34.5c2.3 11.4 12.4 20 24.5 20h10c2.8 0 5 2.2 5 5 0 2.8-2.2 5-5 5h-10c-17.6 0-32-13.1-34.5-30h-15.5c-2.8 0-5-2.2-5-5 0-2.8 2.2-5 5-5h15.5c2.5-16.9 16.9-30 34.5-30z" />
        <path
          fillRule="evenodd"
          d="m175 35c0 2.8-2.2 5-5 5-21.6 0-43.6 6.3-55.9 11.4 32.7 6.6 55.9 36 55.9 73.6v40c0 19.3-15.7 35-35 35h-70c-19.3 0-35-15.7-35-35v-40c0-38 23.8-67.6 57-73.8-11-5.1-31.8-11.2-57-11.2-2.8 0-5-2.2-5-5 0-2.8 2.2-5 5-5 17.2 0 35.6 3.2 49.9 7.9l-11.3-14.2c-6.2-7.7-4.9-13.5-3.6-16.3 1.4-2.8 5.1-7.4 15-7.4h40c9.9 0 13.6 4.6 15 7.4 1.3 2.8 2.6 8.6-3.6 16.3l-11.9 14.9c15.2-5.1 34.8-8.6 50.5-8.6 2.8 0 5 2.2 5 5zm-98.6-17.5l15 18.8c4.7 5.8 12.5 5.8 17.2 0l15-18.8c2.2-2.7 2.8-4.9 2.4-5.7-0.5-0.9-2.5-1.8-6-1.8h-40c-3.5 0-5.5 0.9-5.9 1.8-0.5 0.8 0.1 3 2.3 5.7zm23.6 42.5c-34.8 0-60 27.3-60 65v40c0 13.8 11.2 25 25 25h70c13.8 0 25-11.2 25-25v-40c0-37.7-25.2-65-60-65z"
        />
      </g>
    </svg>
  );
};

export default MoneyBag;
