import styled from "styled-components";

import { device, theme } from "../../../Theme";

const Wrapper = styled.div`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  height: ${({ height }) => height};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  cursor: ${({ cursor }) => cursor};
  display: ${({ flex }) => (flex ? "flex" : "")};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  z-index: ${({ zIndex }) => zIndex};
  background ${({ background }) => background};
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : null};
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  background-size: ${({ backgroundSize }) => backgroundSize};
  background-repeat: ${({ backgroundRepeat }) => backgroundRepeat};
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: ${({ overflow }) => overflow};
  position: ${({ position }) => position};
  box-shadow: ${({ boxShadow }) => boxShadow};
  transition: ${({ transition }) => transition};
  writing-mode: ${({ writingMode }) => writingMode};
  transform: ${({ transform }) => transform};
  text-align: ${({ textAlign }) => textAlign};
  border: ${({ border }) => border};
  border-style: ${({ borderStyle }) => borderStyle};
  border-bottom: ${({ borderBottom }) => borderBottom};
  display: ${({ display }) => display};
  overflow-y: ${({ overflowY }) => overflowY};
  min-height: ${({ minHeight }) => minHeight};
  gap:${({ gap }) => gap};
  
  @media ${device.sm} {
    display: ${({ tabletDisplay }) => tabletDisplay};
    flex-direction: ${({ tabletFlexDirection }) => tabletFlexDirection};
    width: ${({ tabletWidth }) => tabletWidth};
    height: ${({ tabletHeight }) => tabletHeight};
    padding: ${({ tabletPadding }) => tabletPadding};
    align-items: ${({ tabletAlignItems }) => tabletAlignItems};
    justify-content: ${({ tabletJustifyContent }) => tabletJustifyContent};
    margin: ${({ tabletMargin }) => tabletMargin};
    overflow: ${({ tabletOverflow }) => tabletOverflow};
    flex-wrap: ${({ tabletFlexWrap }) => tabletFlexWrap};
    top: ${({ tabletTop }) => tabletTop};
    left: ${({ tabletLeft }) => tabletLeft};
    right: ${({ tabletRight }) => tabletRight};
    bottom: ${({ tabletBottom }) => tabletBottom};
    text-align: ${({ tabletTextAlign }) => tabletTextAlign};
  }
  @media ${device.lg} {
    display: ${({ laptopDisplay }) => laptopDisplay};
    flex-direction: ${({ laptopFlexDirection }) => laptopFlexDirection};
    width: ${({ laptopWidth }) => laptopWidth};
    height: ${({ laptopHeight }) => laptopHeight};
    padding: ${({ laptopPadding }) => laptopPadding};
    margin: ${({ laptopMargin }) => laptopMargin};
    margin-top: ${({ laptopMarginTop }) => laptopMarginTop};
    justify-content: ${({ laptopJustifyContent }) => laptopJustifyContent};
    align-items: ${({ laptopAlignItems }) => laptopAlignItems};
    overflow: ${({ laptopOverflow }) => laptopOverflow};
    display: ${({ laptopDisplay }) => laptopDisplay};
    transform: ${({ laptopTransform }) => laptopTransform};
    writing-mode: ${({ laptopWritingMode }) => laptopWritingMode};
    position: ${({ laptopPosition }) => laptopPosition};
    top: ${({ laptopTop }) => laptopTop};
    left: ${({ laptopLeft }) => laptopLeft};
    right: ${({ laptopRight }) => laptopRight};
    bottom: ${({ laptopBottom }) => laptopBottom};
    flex-wrap: ${({ laptopFlexWrap }) => laptopFlexWrap};
    text-align: ${({ laptopTextAlign }) => laptopTextAlign};
  }
  @media ${device.xl} {
    width: ${({ desktopWidth }) => desktopWidth};
    margin: ${({ desktopMargin }) => desktopMargin};
    height: ${({ desktopHeight }) => desktopHeight};
    top: ${({ desktopTop }) => desktopTop};
    left: ${({ desktopLeft }) => desktopLeft};
    right: ${({ desktopRight }) => desktopRight};
    bottom: ${({ desktopBottom }) => desktopBottom};
  }

  .termsLink {
    color: rgba(255,255,255, .5);
    text-decoration: none;
    font-size: .8rem;
    transition: all 300ms ease-in-out;
  }

  .termsLink:hover {
    color: rgba(255,255,255, .8);
    transition: all 300ms ease-in-out;
  }
`;

export default Wrapper;
