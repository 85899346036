const Delivery = ({ fill }) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="200"
      height="200"
    >
      <g fill={fill}>
        <path
          fillRule="evenodd"
          d="m41.7 85.8h-41.2v-6.3h41.2v-14h-21v-6.3h21v-25.2h97.5v28.3h30.7l30.1 30.1v57.5h-10.9c-1.5 9.1-9.3 16-18.8 16-9.4 0-17.3-6.9-18.8-16h-61.4c-1.5 9.1-9.3 16-18.8 16-9.4 0-17.3-6.9-18.8-16h-10.9v-43.9h-29.8v-6.3h29.8c0.1 0 0.1-13.9 0.1-13.9zm42.4 61c0-7-5.7-12.7-12.8-12.7-7 0-12.7 5.7-12.7 12.7 0 7.1 5.7 12.8 12.7 12.8 7.1 0 12.8-5.7 12.8-12.8zm99 0c0-7-5.7-12.7-12.8-12.7-7 0-12.7 5.7-12.7 12.7 0 7.1 5.7 12.8 12.7 12.8 7.1 0 12.8-5.7 12.8-12.8zm-43.9-78.2q0 0 0 0zm0 0c0 0 0 56.6 0 56.6h-91.2v18.4h4.6c1.5-9 9.3-15.8 18.7-15.8 9.4 0 17.3 6.8 18.8 15.8h61.5c1.5-9 9.3-15.8 18.7-15.8 9.4 0 17.3 6.8 18.8 15.8h4.6v-48.6l-26.4-26.4zm-80.3 10.9v6.3h-11v13.9h24.5v6.3h-24.5v12.9h84.9v-78.6h-84.9v18.9h20v6.3h-20v14z"
        />
        <path
          fillRule="evenodd"
          d="m183.8 113.1h-34.6v-34.6h13.8l20.8 20.8zm-6.3-11.3l-17.1-17.1h-4.9v22.1h22z"
        />
      </g>
    </svg>
  );
};

export default Delivery;
