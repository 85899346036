import React, {useState, useEffect} from "react";

import PropTypes from "prop-types";
import Wrapper from "./Wrapper";
import Container from "../../styled_components/Container";
import Heading from "../../styled_components/Heading";
import Form from "../Form";

import WavyBg from "../../../SVG/wavyBg";

const Header = ({props, data}) => {
  const [distance, setdistance] = useState(0)
  
  window.onscroll = function() { 
    setdistance(window.pageYOffset)
  }
  
  return (
    <Wrapper {...props}>
      <Container width="100%" display="flex">
        <Container
          maxWidth="1440px"
          laptopPadding="0 144px"
          width="100%"
          display="flex"
          flexDirection="column"
          laptopFlexDirection="row"
          justifyContent="center"
          margin="3rem auto 0"
        >
          <Container
            position='absolute'
            top='-70px'
            left='50%'
            flex
            alignItems="center"
            justifyContent="center"
            transform= 'translate(-50%, 0)'
            width='350px'
            height='175px'
            borderRadius='0 0 510px 510px'
            backgroundColor="#213534"
          >
            <Container position='relative' top='25px' width="50%">
              <img style={{width: '100%'}} src='/imgs/diatech-logo.png'/>
            </Container>
          </Container>
          <Container
            width="80%"
            laptopWidth="50%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            laptopAlignItems="baseline"
            margin="2rem auto"
          >
            <Heading fontSize='3rem' margin='3rem 0' fontWeight='bold' textColor="#000">
              {data.Header.title}
            </Heading>
            <Container width='100%' laptopWidth="90%" zIndex='9999'>
              <Form data={data.siteForm} policy={data.policy} />
            </Container>
          </Container>
          <Container
            width="100%"
            laptopWidth="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Container
              position='relative'
              zIndex='-9999'
              width="100%"
              laptopWidth="80%"
              tabletWidth='100%'
              height="80%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="5rem 0"
              laptopMargin="4rem 0 0 0"
            >
              <Container laptopDisplay={distance > 810 ? 'none' : 'block'} position='relative' laptopPosition='fixed'  laptopTop='15%' laptopLeft={data.Header.right} >
                <Container 
                className='backgroundCircle'
                width="400px"
                height='400px'
                tabletWidth='500px'
                tabletHeight='500px'
                laptopWidth="300px"
                laptopHeight="300px"
                borderRadius="50%"
                backgroundColor="#1abc9c"
                position="absolute"
                left='3%'
                tabletLeft='8%'
                laptopLeft="0"
                laptopTop="0"
                zIndex='-1'
              ></Container>
                <Container laptopMarginTop={data.Header.top}>
                <img
                  style={{width:data.Header.size}}
                  className="counter-main-image"
                  src={data.Header.img}
                  alt=""
                />
              </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container position="absolute" width="100%" bottom="-1%" tabletBottom='-1%' laptopBottom="-5%">
        <WavyBg
          className="wavyBg1"
          svgId="0"
          primaryFill="#1abc9c"
          secondaryFill="#1abc9c"
        />
        <WavyBg
          className="wavyBg2"
          svgId="1"
          primaryFill="#0b03c2"
          secondaryFill="#00ffca"
        />
      </Container>
    </Wrapper>
  );
};

export default Header;
