const Hand = ({ fill }) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="200"
      height="200"
    >
      <path
        id="Layer"
        fillRule="evenodd"
        fill={fill}
        d="m5.6 50h38.8c3.1 0 5.6 2.5 5.6 5.6v64.5l63.2 18.4 73.4-42.4c1.4-1 2.3-2.6 2.3-4.4 0-3.1-2.4-5.5-5.5-5.6h-0.1c-0.3 0.1-0.6 0.2-1.1 0.3-1.2 0.2-2.8 0.6-4.7 1.1-3.8 0.9-8.8 2.2-13.7 3.4-7.1 1.8-10.8 2.8-14.1 3.6 0.2 0.9 0.3 1.8 0.3 2.7 0 9.2-7.5 16.7-16.7 16.7h-41.6c-1.5 0-3-0.6-4-1.6-1.1-1-1.7-2.5-1.7-4 0-1.4 0.6-2.9 1.7-3.9 1-1.1 2.5-1.6 4-1.6h41.6c3.2 0 5.6-2.4 5.6-5.6 0-3.1-2.4-5.5-5.6-5.5h-27.7c-1.2 0-2.4-0.4-3.3-1.2-8.8-6.5-17.8-13.8-36.5-16.9-2.8-0.5-4.8-3-4.6-5.8 0.2-2.9 2.5-5.1 5.4-5.2q0.5 0 1 0.1c19.9 3.3 31.1 11.6 39.6 17.9h26.1c4.1 0 7.8 1.4 10.7 3.9 1.8-0.5 8.4-2.2 17.1-4.4 4.9-1.2 9.9-2.4 13.8-3.4 2-0.4 3.7-0.9 5-1.2 0.6-0.1 1.1-0.2 1.6-0.3 0.5-0.1 0.6-0.2 1.8-0.2 9.2 0 16.7 7.5 16.7 16.7 0 5.4-2.6 10.3-6.7 13.3q-0.2 0.2-0.5 0.4l-76.1 43.8c-1.3 0.8-2.9 1-4.4 0.5l-62.3-18.1v7.3c0 3.1-2.5 5.6-5.6 5.6h-38.8c-3.1 0-5.6-2.5-5.6-5.6v-83.3c0-2.9 2.2-5.3 5-5.6q0.3 0 0.6 0zm5.5 83.3h27.8v-72.2h-27.8z"
      />
    </svg>
  );
};

export default Hand;
