import React from 'react';

import Container from '../../styled_components/Container';
import Heading from '../../styled_components/Heading';

import data from '../../../data/dataTV';

import GreenBg from '../../../SVG/green';
import Form from '../Form';

function Ambiente() {
  return (
      <>
        <Container
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-end"
            backgroundColor="white"
        >
        <Container width="90%" laptopWidth="70%" display="flex">
          <GreenBg fill="#1abc9c" />
        </Container>
        <Container
          width="100%"
          backgroundColor="#1abc9c"
          display="flex"
          flexDirection="column"
          laptopFlexDirection="row"
          justifyContent="center"
          alignItems="center"
          laptopAlignItems="flex-start"
          padding="5rem 0"
        >
          <Container width="70%" laptopWidth="35%" padding="1rem">
            <Heading fontSize="2.5rem" textColor='#fff' margin='3rem 0' fontWeight='bold' >
                {data.ambiente.title}
            </Heading>
            <Heading  fontSize='1.5rem' textColor="#fff">
                {data.ambiente.title1}
            </Heading>
            <Heading  fontSize='1.5rem' textColor="#fff">
                {data.ambiente.title2}
            </Heading>
          </Container>
          <Container width="80%" laptopWidth="30%">
            <Form />
          </Container>
        </Container>
      </Container>
      <Container
        width="100%"
        backgroundColor="#000"
        display="flex"
        justifyContent="center"
        padding="2rem 0"
      >
        <Container
          maxWidth="1440px"
          display="flex"
          width="100%"
          justifyContent="space-around"
          laptopJustifyContent="space-around"
          alignItems="center"
        >
          <img src="/imgs/diatech-logo.png" alt="Diatech Logo" width="120" />
          <a className="termsLink" href="https://diatech.pt/site/privacidade/">
            {data.ambiente.privacy}
          </a>
        </Container>
      </Container>
    </>
  )
}

export default Ambiente;