import PropTypes from "prop-types";
import Wrapper from "./Wrapper";

const Counter = (props) => {
  return (
    <Wrapper {...props}>
      <div className="counter-number">
        <span>{props.number}</span>
        {props.postfix && <span>{props.postfix}</span>}
      </div>
      <div className="counter-text">
        <p>{props.text}</p>
      </div>
    </Wrapper>
  );
};

export default Counter;
