import React from 'react';

import Container from '../../styled_components/Container';
import Heading from '../../styled_components/Heading';
import Counter from '../Counter';

export default function PorqueDiatech({data}) {
  return (
    <Container width="100%"  backgroundColor="white">
        <Container
          maxWidth="1440px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="70vh"
          margin="0 auto"
          padding="3rem 0"
          overflow="hidden"
        >
          <Heading textAlign="center" fontWeight='bold' fontSize='2.5rem' margin='1rem'>
            Porquê a Diatech?
          </Heading>
          <Container
            display="flex"
            flexDirection="column"
            laptopFlexDirection="row"
            justifyContent="center"
            width="100%"
          >
            <Container
              laptopPosition='absolute'
              width="100%"
              laptopWidth="100%"
              minHeight="30vh"
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="flex-start"
              margin="3rem 0"
              zIndex='5'
            >
              {data &&
                data.porqueDiatech.map((item, idx) => {
                  return (
                    <>
                      <Counter
                        key={idx}
                        text={item.text}
                        number={item.number}
                        postfix={item.postfix}
                      />
                    </>
                  );
                })}
            </Container>
            <Container
              width="100%"
              tabletWidth='100%'
              laptopWidth="60%"
              display="flex"
              justifyContent="center">
              <Container
                position="relative"
                width="100%"
                tabletWidth='60%'
                height="100%"
                flex
                alignItems="center"
              >
                <img
                  style={{width:'95%'}}
                  className="counter-main-image"
                  src={data.ImgComoFunciona.img}
                  alt=""
                />
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
  )
}
