import React, { useState } from "react";
import axios from 'axios';

import PropTypes from "prop-types";
import Wrapper from "./Wrapper";
import Heading from "../../../../src/Components/styled_components/Heading";
import Container from "../../styled_components/Container";
import {useNavigate} from 'react-router-dom';

const Form = ({props, data, policy}) => {
  const [formData, setFormData] = useState({ termsAndConditions: false });
  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState();
  const navigate = useNavigate();
  const handleChange = (e) => {
    let newFormData = {
      ...formData,
      data,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const toggleCheckbox = (e) => {
    let newFormData = {
      ...formData,
      [e.target.name]: !formData[e.target.name],
    };
    setFormData(newFormData);
  };


  const handleSubmit = () => {
    if (formData.termsAndConditions === false && !formData.name && !formData.phone && !formData.email ) {
      setFormError('Por favor preencha o formulário');
      return;
    }
    if (formData.termsAndConditions === false ) {
      setFormError('Aceitar termos e Condições');
      return;
    }
    if (!formData.name) {
      setFormError('Por favor preencher o nome');
      return;
    }
    if (!formData.email) {
      setFormError('Por favor preencher o email');
      return;
    } 
    if (!formData.phone) {
      setFormError('Por favor preencher o telemóvel');
      return;
    }
    
    axios.post('https://landing-diatech-server.gci-id.com', formData).then((res) => { 
        if (res.data.status === 202) {
          setSubmitted(true);
          setFormError('');
          navigate('/obrigado');
        } else {
          setSubmitted(false);
        }
    }).catch(function (error) {
        console.log(error);
    })
  };
  
  return (
    <Wrapper {...props}>
      <h2>Preencha o formulário e entraremos em contacto gratuitamente!</h2>
      <div style={{display:'none'}} className="formCol">
        <span>Site</span>
        <input type="text" name="site" defaultValue={data} required onChange={handleChange} />
      </div>
      <div className="formCol">
        <span>Nome*</span>
        <input type="text" name="name" required onChange={handleChange} />
      </div>
      <div className="formCol">
        <span>Email*</span>
        <input type="email" name="email" required onChange={handleChange} />
      </div>
      <div className="formCol">
        <span>Telemóvel*</span>
        <input type="tel" name="phone" required onChange={handleChange} />
      </div>
      <div className="formRow">
        <input
          type="checkbox"
          name="termsAndConditions"
          required
          onChange={toggleCheckbox}
          checked={formData.termsAndConditions}
        />
        <span>Li e Aceito as Políticas de Privacidade</span>
      </div>
      {submitted === false ? <button onClick={handleSubmit}>QUERO SER CONTACTADO</button> : <button>Obrigado pelo envio. <br/> Será contactado brevemente.</button> }
      {formError ? <Heading margin="1rem 0" textColor="red" textAlign="center">{formError}</Heading> : ''}
      <Container margin="1rem auto 0"  width="80%">
        <Heading textAlign="center" fontSize='14px'>{policy ? policy : ''}</Heading>
      </Container>
    </Wrapper>
  );
};

export default Form;
