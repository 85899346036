import React from 'react';

import Container from '../../styled_components/Container';
import Heading from '../../styled_components/Heading';

import Testemunhos from '../Testemunhos';

import WavyBg from '../../../SVG/wavyBg';

export default function OQueDizem({data}) {
  return (
      <>
    <Container width="100%" padding="5rem 0" backgroundColor="#1abc9c">
    <Container
      maxWidth="1440px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="50vh"
      margin="0 auto"
    >
        <Heading textAlign='center' fontSize="2.5rem" textColor='#fff' margin='3rem 0' fontWeight='bold' >
          O que dizem os nossos clientes
        </Heading>
      <Container width="80%">
        <Testemunhos testimonials={data.testemunhos} />
      </Container>
    </Container>
  </Container>
  <Container transform="rotate(180deg)" display="flex" margin='-1px'>
    <WavyBg svgId="3" fill='"#1abc9c"' primaryFill="#1abc9c" secondaryFill="#1abc9c" />
  </Container></>
  )
}
