import styled from "styled-components";

import { device, theme } from "../../../Theme";
const Wrapper = styled.div`
  position: ${({ position }) => position};
  border-radius: ${({ borderRadius }) => borderRadius}; 
  background-color: ${({ backgroundColor }) => backgroundColor};  
  display: ${({ flex }) => (flex ? "flex" : "")};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  height: ${({ height }) => height};
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ textColor }) => textColor};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "")};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  line-height: ${({ lineHeight }) => lineHeight};

  @media ${device.mobileL} {
    justify-content: ${({ mobileLjustifyContent }) => mobileLjustifyContent};
  }
  @media ${device.tablet} {
    width: ${({ tabletWidth }) => tabletWidth};
    font-size: ${({ fontSizeTablet }) => fontSizeTablet};
    height: ${({ tabletHeight }) => tabletHeight};
    text-align: ${({ tabletTextAlign }) => tabletTextAlign};
  }

  @media ${device.laptop} {
    text-align: ${({ laptopTextAlign }) => laptopTextAlign};
    font-size: ${({ laptopFontSize }) => laptopFontSize};
    margin: ${({ laptopMargin }) => laptopMargin};
    width: ${({ laptopWidth }) => laptopWidth};
  }

  @media ${device.laptopL} {
    font-size: ${({ laptopLFontSize }) => laptopLFontSize};
    margin: ${({ laptopLMargin }) => laptopLMargin};
    width: ${({ laptopLWidth }) => laptopLWidth};
  }
`;

export default Wrapper;
