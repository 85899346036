import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fonts.primary};
    margin: 0;
    padding: 0;
  }
  .wavyBg3 {
    width: 100%;
  }
`;

export default GlobalStyles;
