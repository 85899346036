import React, {useState} from "react";

import Container from "../../styled_components/Container";
import Heading from "../../styled_components/Heading";

const Header = ({props, data}) => {
    const [distance, setdistance] = useState(0)

    window.onscroll = function() {
        setdistance(window.pageYOffset)
    }

    return (
        <Container width="100%" display="flex" flexDirection="column" minHeight="100vh" justifyContent="space-between">
            <Container width="100%" display="flex">
                <Container
                    maxWidth="1440px"
                    laptopPadding="0 144px"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    laptopFlexDirection="row"
                    justifyContent="center"
                    margin="3rem auto 0"
                >
                    <Container
                        position='absolute'
                        top='-70px'
                        left='50%'
                        flex
                        alignItems="center"
                        justifyContent="center"
                        transform= 'translate(-50%, 0)'
                        width='350px'
                        height='175px'
                        borderRadius='0 0 510px 510px'
                        backgroundColor="#213534"
                    >
                        <Container position='relative' top='25px' width="50%">
                            <img style={{width: '100%'}} src='/imgs/diatech-logo.png'/>
                        </Container>
                    </Container>
                </Container>
            </Container>
            <Container
                maxWidth="1440px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="70vh"
                margin="0 auto"
                padding="3rem 0"
                overflow="hidden"
            >
                <Heading textAlign="center" fontWeight='bold' fontSize='2.5rem' margin='1rem'>
                    Obrigado.<br /> Recebemos os seus dados e entraremos em contacto.
                </Heading>
            </Container>
            <Container
                width="100%"
                backgroundColor="#000"
                display="flex"
                justifyContent="center"
                padding="2rem 0"
            >
                <Container
                    maxWidth="1440px"
                    display="flex"
                    width="100%"
                    justifyContent="space-around"
                    laptopJustifyContent="space-around"
                    alignItems="center"
                >
                    <img src="/imgs/diatech-logo.png" alt="Diatech Logo" width="120" />
                    <a className="termsLink" href="https://diatech.pt/site/privacidade/">Política de Privacidade</a>
                </Container>
            </Container>
        </Container>
    );
};

export default Header;
